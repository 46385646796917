<template>
  <component :is="layoutName" />
  <modals-container></modals-container>
  <div id="login"></div>
</template>

<script>

import '@/styles/reset.css'

import { getAuthToken, getAuthCookie, removeAuthData } from '@/libs/auth-helper'
import { ModalsContainer } from 'vue-final-modal'

import { isMobile } from '@/libs/utils'

import DefaultLayout from '@/layouts/DefaultLayout'
import MobileDefaultLayout from '@/layouts_mobile/MobileDefaultLayout'
import MobileEmptyLayout from '@/layouts_mobile/MobileEmptyLayout'
import { mapMutations, mapState } from 'vuex'

import Stomp from 'webstomp-client'
import SockJS from 'sockjs-client'
import { SOCKET_SERVER_URL, SOCKET_SUBSCRIBE_DESTINATION } from '@/libs/constants'
import { signOut } from '@/api/member'
import store from '@/store'
import EmptyLayout from '@/layouts/EmptyLayout'
import { decrypt } from '@/libs/crypto'

export default {
  name: 'App',
  components: {
    'default-layout': DefaultLayout,
    'empty-layout': EmptyLayout,
    'mobile-default-layout': MobileDefaultLayout,
    'mobile-empty-layout': MobileEmptyLayout,
    ModalsContainer
  },
  mounted () {
    const scripts = [
      '/js/swiper.min.js'
    ]
    scripts.forEach(script => {
      const tag = document.createElement('script')
      tag.setAttribute('src', script)
      document.head.appendChild(tag)
    })
    this.socketConnect()
  },
  data () {
    return {
      hasError: false,
      isMobile: isMobile(),
      isConnectSocket: false,
      stompClient: null
    }
  },
  computed: {
    ...mapState([
      'userData',
      'siteCheck',
      'coinAmt'
    ]),
    layoutName () {
      if (!this.$route.matched.length) {
        return null
      }

      const route = this.$route

      const layout = route ? route.meta.layout : 'default'
      return `${layout}-layout`
    }
  },
  watch: {
    siteCheck () {
      this.checkSiteOption()
    },
    async coinAmt () {
      if (this.coinAmt === 'TK999') {
        if (window.gamePopupTest) {
          await window.gamePopupTest.close()
        }
        if (location.pathname !== '/slot/game') {
          this.signOut()
          this.onAlert('warningart', '같은 아이디로 로그인되었습니다.')
        }
      }
    },
    userData (newValue, oldValue) {
      console.log('userData: ', newValue, oldValue)
      if (newValue && !oldValue) {
        this.socketConnect()
        this.loadPopupLogin()
      }
    },
    $route: {
      handler (current) {
        if (current.name !== null) {
          this.checkSiteOption()
          this.syncAuthTokenAndCookie()
          const guestOnly = current.meta.guestOnly
          const forAll = current.meta.forAll
          const authToken = getAuthToken()

          this.socketConnect()

          if (authToken) {
            this.getGameCoin()
          }

          if (guestOnly) {
            if (authToken) {
              // this.$router.replace({ name: 'dashboard' })
            }
          } else if (!forAll) {
            if (!authToken) {
              // this.signOut()
              // this.$router.push({ name: 'sign-in' })
            }
          }
        }
      },
      immediate: true
    }
  },
  async created () {
    const currentToken = getAuthToken()
    if (!this.userData && currentToken) {
      await store.dispatch('storeUserData')
    }
    await this.loadGameGroupCode()
    await this.loadCommonCode()
    this.loadPopup()
    this.loadCheckOption()
    this.loadSiteOption()
    this.loadCategoryCode()
    this.loadBanner()
    console.log('app created')

    setInterval(() => {
      if (this.userData) {
        this.getGameCoin()
      }
    }, 5000, this)
  },
  methods: {
    ...mapMutations([
      'setErrorData',
      'clearAllUserData'
    ]),
    checkSiteOption () {
      if (this.siteCheck) {
        const endDate = new Date(this.siteCheck.endDate)
        const startDate = new Date(this.siteCheck.startDate)
        const today = new Date()

        if (startDate <= today && endDate >= today) {
          this.goPageByName('warning')
        }
      }
    },
    loadPopup () {
      store.dispatch('storePopup')
    },
    loadPopupLogin () {
      store.dispatch('storePopupLogin')
    },
    loadBanner () {
      store.dispatch('storeBanner')
    },
    loadGameGroupCode () {
      store.dispatch('storeGameGroupCodes')
    },
    loadCommonCode () {
      store.dispatch('storeCommonCode')
    },
    loadCategoryCode () {
      store.dispatch('storeCategoryCode')
    },
    loadSiteOption () {
      store.dispatch('storeSiteOption')
    },
    loadCheckOption () {
      store.dispatch('storeSiteCheck')
    },
    getGameCoin () {
      store.dispatch('storeUserCoinAmt')
    },
    socketConnect (type = 'member', chatId = '1') {
      const token = getAuthToken()
      if (!token) {
        if (this.stompClient) {
          this.stompClient.client.disconnect()
        }
      }
      if (token && !this.stompClient) {
        this.stompClient = {
          client: null,
          token: token,
          type: type,
          chatId: chatId
        }
        const serverURL = SOCKET_SERVER_URL
        const socket = new SockJS(serverURL)
        this.stompClient.client = Stomp.over(socket, { debug: false })
        this.stompClient.client.connect(
          {},
          frame => {
            // 소켓 연결 성공
            this.isConnectSocket = true
            // 서버의 메시지 전송 endpoint를 구독합니다.
            // 이런형태를 pub sub 구조라고 합니다.
            const currentToken = getAuthToken()
            this.stompClient.client.subscribe(SOCKET_SUBSCRIBE_DESTINATION + currentToken, res => {
              const jsonData = decrypt(res.body)
              let content = jsonData.content || null
              if (content) {
                content = JSON.parse(content)
                for (let i = 0, iLen = content.length; i < iLen; i++) {
                  const item = content[i]
                  if (item.cmd === 'INSPECT') {
                    if (item.data1 && item.data1 === 'Y' && this.userData) {
                      this.signOut()
                      this.goPageByName('warning')
                    }
                  }

                  if (item.cmd === 'CASH') {
                    if (item.data1 && this.userData) {
                      store.dispatch('storeUserCash', item.data1)
                    }
                    if (item.data2 && this.userData) {
                      store.dispatch('storeUserPoint', item.data2)
                    }
                  }

                  if (item.cmd === 'MSG') {
                    if (item.data1 && this.userData) {
                      store.dispatch('storeUserUnReadMessageCount', item.data1)
                    }
                  }

                  if (item.cmd.trim() === 'LOGOUT') {
                    console.log(11111)
                    this.signOut()
                  }

                  if (item.cmd.trim() === 'LOCK_BET_YN') {
                    // memLock  N ,betYn Y 일 때만 게임 가능
                    let memLock = ''
                    let betYn = ''
                    // memLock  N
                    if (item.data1 && this.userData) {
                      memLock = item.data1
                    }

                    // betYn Y
                    if (item.data2 && this.userData) {
                      betYn = item.data2
                    }

                    if (memLock !== 'N' || betYn !== 'Y') {
                      this.emitter.emit('gameDown', true)
                    }
                  }
                }
              }
            })
          },
          error => {
            // 소켓 연결 실패
            console.error(error)
            this.socketConnect()
            this.isConnectSocket = false
          }
        )
      }
    },
    syncAuthTokenAndCookie () {
      const authCookie = getAuthCookie()

      if (!authCookie) {
        removeAuthData()
      }
    },
    signOut () {
      signOut({}).then(response => {
        if (this.stompClient) {
          this.stompClient.client.disconnect()
          this.stompClient = null
        }
        this.isConnectSocket = false
        this.clearAllUserData()
        removeAuthData()
      })
    }
  }
}
</script>

<!--
<style src="@/styles/header.css" v-if="!isMobile"></style>
<style src="@/styles/footer.css" v-if="!isMobile"></style>
<style src="@/styles/common.css" v-if="!isMobile"></style>
<style src="@/styles/main.css" v-if="!isMobile"></style>
<style src="@/styles/subcommon.css" v-if="!isMobile"></style>
<style src="@/styles/swiper.min.css" v-if="!isMobile"></style>
-->

<style v-if="!hasError">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
