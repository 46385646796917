import { createStore } from 'vuex'
import { IS_PRODUCTION } from './libs/constants'
import { getUserInfo } from '@/api/member'
import { getPopupCookie, removeAuthData, tokenCheck } from '@/libs/auth-helper'
import { commonBoardCode, commonCode, gameGroupCodes } from '@/api/common'
import { getGameMoney } from '@/api/cash'
import { getBanner, getPopup, getPopupLogin, getSiteCheckOption, getSiteOption } from '@/api/main'
// import { getUserInfo } from '@/api/member'

export default createStore({
  state: {
    errorData: null,
    userData: null,
    commonCode: null,
    commonCodeByCode: null,
    commonCodeByOrder: null,
    gameCount: null,
    coinAmt: null,
    siteOption: null,
    siteCheck: null,
    categoryCode: null,
    categoryCodeByCode: null,
    banner: null,
    popup: [],
    popupLogin: [],
    gameGroupCodes: null,
    unReadMessageCount: 0,
    joinRecommenderId: 'test',
    isPlayGame: false
  },
  getters: {
    banner (state) {
      return state.banner
    },
    popup (state) {
      return state.popup
    },
    popupLogin (state) {
      return state.popupLogin
    },
    userData () {
      return this.state.userData
    },
    commonCode (state) {
      return state.commonCode
    },
    userCoinAmt (state) {
      return state.coinAmt
    },
    siteOption (state) {
      return state.siteOption
    },
    commonCodeByCode (state) {
      return state.commonCodeByCode
    },
    commonCodeByOrder (state) {
      return state.commonCodeByOrder
    },
    categoryCode (state) {
      return state.categoryCode
    },
    categoryCodeByCode (state) {
      return state.categoryCodeByCode
    }
  },
  mutations: {
    setIsPlayGame (state, value) {
      state.isPlayGame = value
    },
    setJoinRecommenderId (state, value) {
      state.joinRecommenderId = value
    },
    setSiteOption (state, value) {
      state.siteOption = value
    },
    setSiteCheck (state, value) {
      state.siteCheck = value
    },
    setUserData (state, value) {
      state.userData = value
    },
    setErrorData (state, payload) {
      state.errorData = payload
    },
    setUserCoinAmt (state, payload) {
      state.coinAmt = payload
    },
    clearAllUserData (state) {
      if (window.gamePopupTest) {
        window.gamePopupTest.close()
      }
      this.state.popupLogin = []
      this.state.userData = null
    },
    setCategoryCode (state, value) {
      console.log('setCategoryCode', value)
      const gameObj = {}
      const obj = {}
      for (let i = 0, iLen = value.length; i < iLen; i++) {
        const item = value[i]
        const code = item.code.trim()
        const groupCode = item.groupCode.trim()

        if (!gameObj[groupCode]) {
          gameObj[groupCode] = []
        }

        if (!obj[groupCode]) {
          obj[groupCode] = {}
        }
        if (!obj[groupCode][code]) {
          obj[groupCode][code] = item
        }

        gameObj[groupCode].push(item)
      }
      console.log(obj, gameObj)
      state.categoryCodeByCode = obj
      state.categoryCode = gameObj
    },
    setCommonCode (state, value) {
      const gameObj = {}
      const gameObjByCode = {}
      const gameCount = {}

      for (let i = 0, iLen = value.length; i < iLen; i++) {
        const item = value[i]
        const code = item.code.trim()
        const codeName = item.codeName.trim()
        const groupCode = item.groupCode.trim()
        const groupCodeNameEN = item.groupCodeNameEN.toString().toLowerCase().replace(/\s/g, '')

        if (!gameCount[groupCodeNameEN]) {
          gameCount[groupCodeNameEN] = 0
        }
        gameCount[groupCodeNameEN]++

        if (!gameObj[groupCode]) {
          gameObj[groupCode] = {}
        }
        if (!gameObj[groupCode][codeName]) {
          gameObj[groupCode][codeName] = item
        }

        if (!gameObjByCode[code]) {
          gameObjByCode[code] = {}
        }
        gameObjByCode[code] = {
          ...item
        }
      }

      const list = value
      list.sort((a, b) => {
        return a.ord > b.ord ? 1 : -1
      })

      const obj = {}

      list.forEach(item => {
        const groupCode = item.groupCode.trim()
        if (!obj[groupCode]) {
          obj[groupCode] = []
        }
        obj[groupCode].push({
          ...item
        })
      })
      this.state.gameCount = gameCount
      this.state.commonCodeByOrder = obj
      this.state.commonCodeByCode = gameObjByCode
      this.state.commonCode = gameObj
    },
    setGameGroupCodes (state, value) {
      state.gameGroupCodes = value
    },
    setUserCash (state, payload) {
      state.userData.cashAmt = payload
    },
    setUserPoint (state, payload) {
      state.userData.pointAmt = payload
    },
    setUserUnReadMessageCount (state, payload) {
      state.unReadMessageCount = payload
    },
    setBanner (state, payload) {
      state.banner = payload
    },
    setPopup (state, payload) {
      state.popup = payload
    },
    setPopupLogin (state, payload) {
      state.popupLogin = payload
    }
  },
  actions: {
    storeBanner ({ dispatch, commit }, payload) {
      return getBanner({ }).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          const data = result.data
          commit('setBanner', data.list)
        }
      })
    },
    storePopup ({ dispatch, commit }, payload) {
      if (this.state.popup.length === 0) {
        return getPopup({ }).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            const data = result.data
            const list = data.list

            list.forEach(item => {
              const siteId = process.env.VUE_APP_SITE_ID || 'moka'
              const name = siteId + '_popup_' + item.bannerIdx
              const cookie = getPopupCookie(name)
              if (cookie) {
                item.isClose = true
              } else {
                item.isClose = false
              }
            })
            commit('setPopup', list)
          }
        })
      }
    },
    storePopupLogin ({ dispatch, commit }, payload) {
      if (this.state.popupLogin.length === 0) {
        return getPopupLogin({ }).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            const data = result.data
            const list = data.list

            list.forEach(item => {
              const siteId = process.env.VUE_APP_SITE_ID || 'moka'
              const name = siteId + '_popup_' + item.bannerIdx
              const cookie = getPopupCookie(name)
              if (cookie) {
                item.isClose = true
              } else {
                item.isClose = false
              }
            })
            commit('setPopupLogin', list)
          }
        })
      }
    },
    storeUserCash ({ dispatch, commit }, payload) {
      commit('setUserCash', payload)
    },
    storeUserPoint ({ dispatch, commit }, payload) {
      commit('setUserPoint', payload)
    },
    storeUserUnReadMessageCount ({ dispatch, commit }, payload) {
      commit('setUserUnReadMessageCount', payload)
    },
    storeSiteOption ({ dispatch, commit }, payload) {
      return getSiteOption({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const data = result.data
          commit('setSiteOption', data.siteOption)
          commit('setJoinRecommenderId', data.joinRecommanderId)
        }
      })
    },
    storeSiteCheck ({ dispatch, commit }, payload) {
      const param = {
        siteId: process.env.VUE_APP_SITE_ID || 'moka'
      }
      return getSiteCheckOption(param).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const data = result.data
          commit('setSiteCheck', data.siteCheckInfo)
        }
      })
    },
    storeUserCoinAmt ({ dispatch, commit }, payload) {
      if (!this.state.userData) {
        return false
      } else {
        return getGameMoney({}).then(res => {
          const result = res.data
          if (result.resultCode === '0') {
            const data = result.data
            commit('setUserCoinAmt', data.balance)
            commit('setIsPlayGame', data.isPlayGame === 'Y')
            if (data.isPlayGame === 'Y') {
              tokenCheck()
            }
          } else if (result.resultCode === 'TK999') {
            commit('setUserCoinAmt', result.resultCode)
          }
        })
      }
    },
    storeUserData ({ dispatch, commit }, payload) {
      return getUserInfo(payload).then(response => {
        const data = response.data
        if (data.resultCode === '0') {
          commit('setUserData', data.data.member)
          return data
        } else {
          removeAuthData()
          this.$router.push('/')
        }
      }).catch(err => {
        console.error('[ERR] storeUserData : ', err)
      })
    },
    storeGameGroupCodes ({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'kplay'
      }
      return gameGroupCodes(payload).then(response => {
        const data = response.data

        commit('setGameGroupCodes', data.data.groupCodes)
        return data
      })
    },
    storeCommonCode ({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'kplay',
        groupCode: '',
        code: ''
      }
      return commonCode(payload).then(response => {
        const data = response.data
        // if (data.resultCode === '0') {
        commit('setCommonCode', data.data.codes)
        // return data
        // }
      }).catch(err => {
        console.error('[ERR] storeCommonCode : ', err)
      })
    },
    storeCategoryCode ({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'bcategory'
      }
      return commonBoardCode(payload).then(response => {
        const data = response.data
        // if (data.resultCode === '0') {
        commit('setCategoryCode', data.data.codes)
        // return data
        // }
      }).catch(err => {
        console.error('[ERR] setCategoryCode : ', err)
      })
    }
  },
  strict: IS_PRODUCTION
})
